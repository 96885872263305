
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
// 多语种动态日志-- 目前只处理分销商系统涉及的日志
import {setConfigCharacters} from "@/utils/common";

export const dynamicLog = (obj, contentType, system) => {
  // 动态类型值
  let typeObj = {
    LF: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001608'),
    FT: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001609')
  }

  // 处理地址的动态日志数据问题
  function handleAddress(obj) {
    let newList = [];
    let addressLoglist = [
      {
        paramsKey: ['oldBuyerName', 'newBuyerName'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001421')} [ ${obj.oldBuyerName} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerName} ]`
      },
      {
        paramsKey: ['oldBuyerEmail', 'newBuyerEmail'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001422')} [ ${obj.oldBuyerEmail} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerEmail} ]`
      },
      {
        paramsKey: ['oldBuyerPhone', 'newBuyerPhone'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001423')} [ ${obj.oldBuyerPhone} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerPhone} ]`
      },
      {
        paramsKey: ['oldBuyerAccountId', 'newBuyerAccountId'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001424')} [ ${obj.oldBuyerAccountId} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerAccountId} ]`
      },
      {
        paramsKey: ['oldBuyerCountryCode', 'newBuyerCountryCode'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001425')} [ ${obj.oldBuyerCountryCode} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerCountryCode} ]`
      },
      {
        paramsKey: ['oldBuyerMobile', 'newBuyerMobile'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001426')} [ ${obj.oldBuyerMobile} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerMobile} ]`
      },
      {
        paramsKey: ['oldBuyerAddress1', 'newBuyerAddress1'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001427')} [ ${obj.oldBuyerAddress1} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerAddress1} ]`
      },
      {
        paramsKey: ['oldBuyerAddress2', 'newBuyerAddress2'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001428')} [ ${obj.oldBuyerAddress2} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerAddress2} ]`
      },
      {
        paramsKey: ['oldBuyerCity', 'newBuyerCity'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001429')}  [ ${obj.oldBuyerCity} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerCity} ]`
      },
      {
        paramsKey: ['oldBuyerState', 'newBuyerState'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001430')} [ ${obj.oldBuyerState} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerState} ]`
      },
      {
        paramsKey: ['oldBuyerPostalCode', 'newBuyerPostalCode'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001431')} [ ${obj.oldBuyerPostalCode} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerPostalCode} ]`
      },
      {
        paramsKey: ['oldBuyerPassportCode', 'newBuyerPassportCode'],
        value: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001432')} [ ${obj.oldBuyerPassportCode} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000540')} [ ${obj.newBuyerPassportCode} ]`
      },
    ];
    if (Object.keys(obj).length > 0) {
      for (let key in obj) {
        addressLoglist.map((item) => {
          if (item.paramsKey.includes(key)) {
            newList.push(item.value)
          }
        })
      }
    }
    newList.unshift(`${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001433')} ${obj.packageCode} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001434')}`);
    let text = [...new Set(newList)].join(';');
    return text;
  }

  // 处理有些动态日志不需要展示在分销商但是需要展示运营
  function handleDynamicLogs(obj, system) {
    let text = '';
    if (contentType === 40) {
      if (system === 'distributor') {
        text = `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001435')}${obj.currency} ${obj.amount}`;
      } else if (system === 'yms') {
        text = `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001436')}${obj.currency} ${obj.amount}`;
      }
    }
    if (contentType === 48) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001437')}${obj.totalPriceCurrency}  ${obj.totalPrice}
         ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001438')}${obj.totalPriceCurrency}  ${obj.distributorStockCost}
         ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001439')}${obj.supplierTotalPriceCurrency}  ${obj.supplierTotalPrice}`
      }
    }
    if (contentType === 52) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${obj.subPackageCode} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001440')}`;
      }
    }
    return text;
  }

  /*;是用于页面显示的时候换行展示*/
  let logsObj = {
    1: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001441')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001442')}`,
    2: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001443')}`,
    3: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001444')}`,
    4: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001445')}`,
    5: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001446')}`,
    6: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001447')}`,
    7: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001448')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')} [ ${obj.packageCode} ]`,
    8: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001450')} ${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')} [ ${obj.packageCode} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001451')} ${obj.ruleName} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001452')} ${obj.ymsLogisName} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001453')} ${typeObj[obj.dynamicMessage]}`,
    9: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001454')}${obj.platformItemSku}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001455')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    10: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001456')}${obj.platformItemSku}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001457')}${setConfigCharacters}SKU`,
    11: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001458')}${obj.platformItemSku}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001455')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    12: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001459')}${obj.platformItemSku}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001455')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001460')}`,
    13: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001459')}${obj.platformItemSku}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001457')}${setConfigCharacters}SKU`,
    14: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001450')} ${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')} [ ${obj.packageCode} ]`,
    15: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')} [ ${obj.packageCode} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001461')}`,
    16: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001462')}${obj.carrierShippingMethodName} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001463')}${obj.trackingNumber} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001464')}${obj.thirdPartyNo}`,
    17: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001465')}${obj.message}`,
    18: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001466')}${obj.trackingNumber}`,
    19: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001467')}${obj.message}`,
    20: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')} [ ${obj.packageCode} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001468')}`,
    21: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')} [ ${obj.packageCode} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001469')}`,
    22: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966')} ${obj.packageCode} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001470')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001471')})`,
    23: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')} [ ${obj.packageCode} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001468')} ${obj.message}`,
    24: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001433')} ${obj.packageCode} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001472')}`,
    25: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001433')} ${obj.packageCode} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001473')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001474')} [ ${obj.oldCarrierContent} ] ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001475')} [ ${obj.newCarrierContent} ]`,
    26: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001433')} ${obj.packageCode} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001476')}`,
    27: handleAddress(obj),
    28: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001477')}`,
    29: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001478')}`,
    30: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000401')}`,
    31: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000400')}`,
    32: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001479')}`,
    33: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001480')}`,
    34: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001481')}${obj.iossName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001482')}${obj.iossCode}`,
    35: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001483')}${obj.iossName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001482')}${obj.iossCode}`,
    36: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001484')}${obj.iossName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001485')}${obj.iossCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001486')}${obj.platformIds}`,
    37: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001484')}${obj.iossName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001485')}${obj.iossCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001487')}${obj.shopIds}`,
    38: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001488')}${obj.iossName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001485')}${obj.iossCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001486')}${obj.platformIds}`,
    39: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001488')}${obj.iossName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001485')}${obj.iossCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001487')}${obj.shopIds}`,
    40: handleDynamicLogs(obj, system),
    41: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001489')}${obj.currency} ${obj.amount}`,
    42: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001490')}${obj.currency} ${obj.amount}`,
    43: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001491')}`,
    44: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001492')}`,
    45: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001493')}`,
    46: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001494')} ${obj.amount}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001495')}${obj.currency}`,
    47: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001496')}`,
    48: handleDynamicLogs(obj, system),
    49: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001497')}`,
    50: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001498')}${obj.estimateArrivalOfGoodsTime}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001499')}${obj.ymsSkuContent}`,
    51: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001500')}${obj.ymsSkuContent}`,
    52: handleDynamicLogs(obj, system),
    53: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001501')}`,
    54: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001502')}`,
    55: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000400')}${obj.cnValue}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000218')}`,
    56: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000401')}${obj.cnValue}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000218')}`,
    57: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001062')}${obj.cnValue}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000218')}`,
    58: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000227')}${obj.content}`,
    59: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001503')}`,
    60: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001504')}`,
    61: `${obj.subPackageCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001440')}`,
    62: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001505')}`,
    63: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001506')}`,
    64: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001507')}${obj.carrierShippingMethodName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001508')}${obj.trackingNumber}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001509')}${obj.thirdPartyNo}`,
    65: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001510')}`,
    66: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001511')}`,
    67: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001512')}`,
    68: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001513')}`,
    69: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001514')}`,
    70: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001515')}`,
    71: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001516')}${obj.rate}`,
    72: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001517')}${obj.rate}`,
    73: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001518')}`,
    74: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001519')}`,
    75: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001520')}`,
    76: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001521')}${obj.ymsPickingOrderCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001522')}`,
    77: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001285')}`,
    78: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001523')}`,
    79: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001524')}`,
    80: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001525')}${obj.code}】`,
    81: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001526')}`,
    82: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001527')}`,
    83: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001528')}`,
    84: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001529')}`,
    85: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001530')}`,
    86: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001531')}`,
    87: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001532')}`,
    88: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000707')}`,
    89: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001533')}${obj.describe}`,
    90: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001534')}  ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001535')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001536')}${obj.describe}`,
    91: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001537')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001535')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000707')}`,
    92: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001534')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001535')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000707')}`,
    93: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001537')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001535')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001538')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001533')}${obj.describe}`,
    94: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001539')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001540')}${obj.subsidyAmount}${obj.subsidyAmountCurrency}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001541')}${obj.modifySubsidyMoney}${obj.subsidyAmountCurrency}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001542')}${obj.describe}`,
    95: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001532')}`,
    96: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001543')}`,
    97: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001544')}`,
    98: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001545')}`,
    99: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001546')}`,
    100: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001547')}`,
    101: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001548')}`,
    102: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001549')}${obj.auditRemarks}`,
    103: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001550')}`,
    104: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001551')}`,
    105: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001552')}`,
    106: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001553')}${obj.carrierName}：${obj.carrierShippingMethodName}`,
    107: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001554')}`,
    108: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001283')}`,
    109: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001555')}${obj.packageCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001556')}${obj.warehouseName}`,
    110: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001558')}${obj.invalidReason || aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001557')}`,
    111: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001559')}${obj.oldName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001560')}${obj.newName}]`,
    112: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966')}${obj.packageCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001450')}${obj.carrierName}_${obj.carrierShippingMethodName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001561')}(${obj.ruleName})`,
    113: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966')}${obj.packageCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001450')}${obj.carrierName}_${obj.carrierShippingMethodName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001562')}`,
    114: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966')}${obj.packageCode}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001450')}${obj.carrierName}_${obj.carrierShippingMethodName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001563')}`,
    115: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001564')}`,
    116: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001565')}`,
    117: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001566')}`,
    118: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001567')}`,
    119: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001568')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001569')}${obj.trackingNumber})`,
    120: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001565')} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001570')}${obj.carrierErrorMsg})`,
    121: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001571')}`,
    122: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001572')}`,
    123: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001573')}`,
    124: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001574')}${obj.businessId}】`,
    125: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001575')}`,
    126: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001576')}`,
    127: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001577')}`,
    128: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001578')}`,
    129: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001579')}`,
    130: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001580')}`,
    131: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001581')}`,
    132: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001582')}`,
    133: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001583')}`,
    134: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001584')}${obj.invalidReason}`,
    135: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001585')}`,
    136: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000519')}`,
    137: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001586')}`,
    138: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001587')}${obj.renewalPrice}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001588')}${obj.renewalMonth} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001589')}`,
    139: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001590')}`,
    140: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001591')}${obj.auditRemarks}`,
    141: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001592')}${obj.content}`,
    142: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001593')}`,
    143: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001594')}${obj.packageTagName}`,
    144: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001595')}${obj.packageTagName}`,
    145: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001596')}`,
    146: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001597')}${obj.content}`,
    147: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001598')}`,
    148: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001599')}`,
    149: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001600')}`,
    150: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001601')}`,
    151: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001602')}${obj.ymsSpu}`,
    152: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001603')}${obj.ymsSku}`,
    153: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001459')}${obj.platformItemSku}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001604')}`,
    159: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001605')}`,
    162: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001606')} (${obj.platformWarehouseName})`,
    163: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001607')}`,
  };
  return logsObj[contentType];

}
