
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
// 拣货状态
import {setConfigCharacters} from "@/utils/common";

export const YMS_STATUS_LIST = [
  {value: 1, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001274')},
  {value: 2, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001275')},
  {value: 3, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001276')},
  {value: 4, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001277')},
  {value: 5, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001278')},
  {value: 6, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001279')},
  {value: 7, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001280')},
  {value: 8, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001281')},
];

// 出库单状态
export const YMS_PACKAGE_INFO_STATUS = [
  {value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001282')},
  {value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001283')},
  {value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001274')},
  {value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001284')},
  {value: 5, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001285')},
  {value: 6, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001286')},
  {value: 7, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001287')},
  {value: 8, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001281')}
];

export const PICK_STATUS_LIST = [
  {
    value: 1,
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001288')
  },
  {
    value: 2,
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001262')
  },
  {
    value: 3,
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001277')
  },
  {
    value: 4,
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001263')
  },
  {
    value: 5,
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001289')
  },
  {
    value: 21,
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001290')
  },
  {
    value: 99,
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001291')
  }
];

export const YMS_PACKAGE_STATUS = [
  {
    value: 0,
    title: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001261')}`
  },
  {
    value: 9,
    title: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001262')}`
  },
  {
    value: 10,
    title: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001263')}`
  },
  {
    value: 11,
    title: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001264')}`
  },
  {
    value: 12,
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001292')
  }
];

export const YMS_SUBPACKAGE_STATUS = [
  {value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001274')},
  {value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001284')},
  {value: 5, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001293')},
  {value: 6, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001286')},
  {value: 7, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001264')},
  {value: 8, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001281')}
];

// listing inputType
export const LISTING_INPUT_TYPE = [
  {
    value: '1',
    label: 'text'
  }, {
    value: '2',
    label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000330')
  }, {
    value: '3',
    label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001294')
  }, {
    value: '4',
    label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000331')
  }, {
    value: '5',
    label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001295')
  }
];


// 规则是否类型
export const RULE_TYPE_IS = ['order-service-A044', 'order-service-A045', 'order-service-A042', 'order-service-A040', 'order-service-A035', 'order-service-A036', 'order-service-A037', 'order-service-A038', 'order-service-A047', 'order-service-A048', 'order-service-A046',
  'CORE-YCCR-045', 'DISTRIBUTOR-DCCR-A024', 'DISTRIBUTOR-DCCR-A028'
];

// 规则单个input 类型
export const RULE_TYPE_INPUT = ['CORE-YCCR-003', 'CORE-YCCR-004', 'DISTRIBUTOR-DCCR-A005', 'DISTRIBUTOR-DCCR-A004'];

// 规则 两个checkbox input 类型
export const RULE_TYPE_TCI = [
  'CORE-YCCR-014', 'CORE-YCCR-015', 'CORE-YCCR-016', 'CORE-YCCR-017', 'CORE-YCCR-018', 'CORE-YCCR-019', 'CORE-YPDR-008', 'CORE-YPDR-006', 'CORE-YPDR-009', 'CORE-YCCR-010', 'CORE-YCCR-013', 'DISTRIBUTOR-DCCR-A012', 'DISTRIBUTOR-DCCR-A016', 'DISTRIBUTOR-DCCR-A017', 'DISTRIBUTOR-DCCR-A018', 'DISTRIBUTOR-DCCR-A019', 'DISTRIBUTOR-DCCR-A020', 'DISTRIBUTOR-DCCR-A021', 'DISTRIBUTOR-DCCR-A022'];

// 平台订单状态
export const PLATFORM_ORDER_STATUS = [
  {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096'), value: 'null'},
  {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001282'), value: 1},
  {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001296'), value: 2},
  {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001297'), value: 3},
  {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001298'), value: 4},
  {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001287'), value: 5},
  {label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001281'), value: 6},
];

// 三方平台订单状态
export const THIRH_PARTY_PLATFORM_ORDER_STATUS = [
  {value: 0, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001299')},
  {value: 1, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001300')},
  {value: 2, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001287')},
  {value: 21, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001301')},
  {value: 3, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001140')},
  {value: 31, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001302')},
  {value: 4, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001281')},
  {value: 41, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001303')}
];

// 供应商--出库单类型
export const OUTBOUNDTYPELIST = [ // 1:销售出库 2:云卖中心仓备货 3:三方仓备货 null:全部
  {value: 0, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {value: 1, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001304')},
  {value: 2, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001305')},
  {value: 3, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001306')},
  {value: 4, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001307')},
];

// 云卖订单状态
export const CLOUDSALESORDERSTATUS = [
  {selected: true, value: 0, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001282')},
  {selected: false, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001283')},
  {selected: false, value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001274')},
  {selected: false, value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001284')},
  {selected: false, value: 5, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001285')},
  {selected: false, value: 6, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001286')},
  {selected: false, value: 7, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001287')},
  {selected: false, value: 8, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001281')}
];

// 销售状态
export const SALESSTATUS = [
  {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000497')},
  {selected: false, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000440')},
  {selected: false, value: 0, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000496')},
];

// 审核状态
export const AUDITSTATUS = [
  {selected: true, value: 'null', title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 0, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001308')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001309')},
  {selected: false, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001310')},
  {selected: false, value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000707')}
];

// 费用类型
export const EXPENSETYPE = [
  {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001311')},
  {selected: false, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001312')},
  {selected: false, value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001313')},
  {selected: false, value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001314')},
  {selected: false, value: 5, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001315')},
  {selected: false, value: 6, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001316')},
  {selected: false, value: 7, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001317')},
  {selected: false, value: 8, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001318')}
];

// 启用停用状态
export const ENABLEDISABLESTATUS = [
  {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000400')},
  {selected: false, value: 0, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000401')}
];

//面单类型
export const EACESHEETTYPE = [
  {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 'LOGIS', title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000109')},
  {selected: false, value: 'YMS', title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000110')},
  {selected: false, value: 'NONE', title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000111')},
  {selected: false, value: 'MIX', title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000112')}
];

// 异常状态
export const ABNORMALSTATE = [
  {selected: true, value: 0, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001319')},
  {selected: false, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000437')}
];

// 商品状态
export const COMMODITYSTATUS = [
  {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 0, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000440')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000497')},
  {selected: false, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000881')},
  {selected: false, value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000882')}
];

// 费用流水状态
export const EXPENSEFLOWSTATUS = [
  {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001320')},
  {selected: false, value: 0, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001321')}
];

// 注册来源
export const REGISTER_SOURCE = [
  {value: 0, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {value: 1, title: 'TONGTOOL'},
  {value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001322')},
  {value: 4, title: 'Shopify'},
  {value: 5, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001323')},
  {value: 6, title: 'H5'},
  {value: 7, title: 'MIAOSHOU'},
];

// 结算状态(供应商系统) 1 待审核状态 2 待确认 3 等待收款/付款 4 已收款/已付款 5 作废
export const SUPPLIER_SETTLEMENTSTATUS = [
  {selected: true, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001324')},
  {selected: false, value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001325')},
  {selected: false, value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001326')}
];
// 结算状态(运营系统)
export const YMS_SETTLEMENTSTATUS = [
  {selected: false, value: 'null', title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001309')},
  {selected: true, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001327')},
  {selected: false, value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001328')},
  {selected: false, value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001329')}
];
// 语种对应
export const mappingLang = {
  EN: 'usName',  // 英文
  FRA: 'frName', // 法文
  GER: 'deName', // 德文
  SPN: 'esName', //西班牙
  NL: 'nlName',  // 荷兰语
  PLN: 'plName', // 波兰
  PT: 'ptName',  // 葡萄牙
  IT: 'itName',  // 意大利
  CN: 'cnName'   // 中文
};

const fixPath = 'https://www.yms.com/yms-distributor-service/filenode/s'; // 固定写死
export const videos = {
  1: fixPath + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001330'),
  2: fixPath + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001331'),
  3: fixPath + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001332'),
  4: fixPath + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001333'),
  5: fixPath + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001334'),
  6: fixPath + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001335'),
};
export const DELIVERYORDERTYPE = [
  // {value: 1, title: '销售出库'},
  {value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001305')},
  {value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001306')},
  {value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001307')}
];

// 多语种名称
export const MULTILINGUALNAMESLIST = {
  CN: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001336'),
  US: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001337'),
// {type: 'frName', name: '法语'},
// {type: 'deName', name: '德语'},
// {type: 'esName', name: '西班牙语'},
// {type: 'nlName', name: '荷兰语'},
// {type: 'plName', name: '波兰语'},
// {type: 'ptName', name: '葡萄牙语'},
// {type: 'itName', name: '意大利语'}
}

// 消息模板
export const INFORMATIONTYPELIST = [
  {
    value: 1,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000437'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001338'),
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001339'),
    dynamicParamList: [aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001340')], // 动态参数
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001341')
  },
  {
    value: 2,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001342'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001343'),
    dynamicParamList: ['X'], // 动态参数
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001344')
  },
  {
    value: 3,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001345'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001346'),
    dynamicParamList: ['X'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001347'),
  },
  {
    value: 4,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001348'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001349'),
    dynamicParamList: ['X'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001350')
  },
  {
    value: 5,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001351'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001352'),
    dynamicParamList: ['X'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001353')
  },
  {
    value: 6,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001354'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001355'),
    dynamicParamList: ['X'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001356')
  },
  {
    value: 7,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000098'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001357'),
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001358'),
    dynamicParamList: [aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001340')],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001359')
  },
  {
    value: 8,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000440'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001360'),
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001361'),
    dynamicParamList: [aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000313'), 'SPU/SKU'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001362')
  },
  {
    value: 9,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001363'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001364'),
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001365'),
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001363'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001366')
  },
  {
    value: 10,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001367'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001368'),
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001369'),
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001367'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001370')
  },
  {
    value: 11,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001371'),  // 触发条件的文案
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001372'), // 消息类型名称
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001373'), // 消息的标题
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001371'),
    dynamicParamList: [aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000869')],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001374')
  },
  {
    value: 12,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001375'),  // 触发条件的文案
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001376'), // 消息类型名称
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001376'), // 消息的标题
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001375'),
    dynamicParamList: [aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001377')],
    messageTemplate: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001265')}<span class="highlight_color">${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001266')}</span>${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001267')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001268')}`
  },
  {
    value: 13,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001378'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001379'),
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001378'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001380')
  },
  {
    value: 14,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001381'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001382'),
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001381'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001383')
  },
  {
    value: 15,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001384'),
    name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001269')}`,
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001384'),
    dynamicParamList: [`${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000446')}`],
    messageTemplate: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001270')}<span class="highlight_color">【${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001271')}</span>${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001272')}`
  },
  {
    value: 16,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001385'),  // 触发条件的文案
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001386'), // 消息类型名称
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001387'), // 消息的标题
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001385'),
    dynamicParamList: [aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001388'), aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000446')],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001389')
  },
  {
    value: 17,
    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001390'),
    name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001391'),
    title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001391'), // 消息的标题
    triggerConditionTxt: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001390'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001392')
  },
];

// 供应商--订单列表--出库单状态
export const SUPPLIEROUTPACKAGEORDERSTATUS = [
  {value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001274')},
  {value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001278')},
  {value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001393')},
  {value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001264')},
  {value: 5, title: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001273')}`},
  {value: 6, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001394')},
  {value: 7, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001291')},
  {value: 8, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001286')}
]

// 平台订单类型
export const PLATFORMORDERTYPE = [
  {value: 'null', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {value: 'GENERAL', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001395')},
  {value: 'TEMU_S', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001396')},
  {value: 'TEMU_P', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001397')},
  {value: 'ONE_STOP_SERVICE', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001398')},
]

// IOSS 类型
export const IOSSTYPE = [
  {value: 1, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001399')},
  {value: 2, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001400')},
  {value: 3, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001401')},
]

// 翻译之后的云卖订单状态(订单轨迹)
export const YMSORDERSTATUSLIST = [
  {sort: 0, value: 'null', key: 'null', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {sort: 1, value: 0, key: 'createdTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001282')},
  {sort: 2, value: 14, key: 'createdTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001402')},
  {sort: 3, value: 13, key: 'createdTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001403')},
  {sort: 4, value: 1, key: 'printTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001404')},
  {sort: 5, value: 2, key: 'pickingFinishTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001405')},
  {sort: 6, value: 3, key: 'packingTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001406')},
  {sort: 7, value: 4, key: 'supperBoxedTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001407')},
  {sort: 8, value: 5, key: 'despatchTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001408')},
  {sort: 9, value: 11, key: 'allottedTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001409')},
  {sort: 10, value: 6, key: 'allottedTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001274')},
  {sort: 11, value: 7, key: 'pickedTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001278')},
  {sort: 12, value: 8, key: 'changedLabelTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001393')},
  {sort: 13, value: 9, key: 'boxedTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001410')},
  {sort: 14, value: 10, key: 'shipedTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001411')},
  {sort: 15, value: 12, key: 'cancelledTime', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001281')},
];

export const ATTRIBUTEMULTILINGUALNAME = [
  {key: 'cnName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000369')},
  {key: 'usName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001412')},
  {key: 'ukName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001413')},
  {key: 'auName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001414')},
  {key: 'euName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001415')},
  {key: 'frName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000374')},
  {key: 'deName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000375')},
  {key: 'esName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000376')},
  {key: 'nlName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000377')},
  {key: 'plName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000378')},
  {key: 'ptName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000379')},
  {key: 'itName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000380')},
  {key: 'roName', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000381')},
];

export const ATTRIBUTEVALUEDATA = [
  {key: 'cnValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000369')},
  {key: 'usValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001412')},
  {key: 'ukValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001413')},
  {key: 'auValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001414')},
  {key: 'euValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001415')},
  {key: 'frValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000374')},
  {key: 'deValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000375')},
  {key: 'esValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000376')},
  {key: 'nlValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000377')},
  {key: 'plValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000378')},
  {key: 'ptValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000379')},
  {key: 'itValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000380')},
  {key: 'roValue', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000381')},
];

export const PRODUCTTYPES = [
  {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000670'), value: 1},
  {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000672'), value: 2},
  {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000671'), value: 3},
  {title: 'POD', value: 4},
];

export const VISIBLEAFTERLOGIN = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001416');

// 无站点的平台
export const NOSITEPLATFORMLIST = [
  'wish', 'joom', '1688', 'aliexpress', 'fruugo', 'ozon', 'shopify', 'temu', 'tiktok',
  'walmart', 'aliexpressChoice', 'alibaba', 'temu_choice'
];

// 分销商（YMS订单、备货单）的付款状态
export const PAYSTATUSLIST = [
  {value: 'null', title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
  {value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001402')},
  {value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001417')},
  {value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001418')},
  {value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001419')},
  {value: 5, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001281')},
  {value: 6, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001420')}
];

